import { render, staticRenderFns } from "./ZengPinKuAdd.vue?vue&type=template&id=2075df96&scoped=true&"
import script from "./ZengPinKuAdd.vue?vue&type=script&lang=js&"
export * from "./ZengPinKuAdd.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2075df96",
  null
  
)

export default component.exports