<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="商品名称">
                <el-input v-model="search.shangPinMC" size="small">
                </el-input>
            </x-search-item>
            <x-search-item label="赠品状态">
                <el-select v-model="search.zengPinZT" size="small">
                    <el-option value="true" label="开启"/>
                    <el-option value="false" label="关闭"/>
                </el-select>
            </x-search-item>
            <div class="x-search-item" style="margin-left: 5px">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
            </div>
        </div>
        <!-- 批量按钮区 -->
        <div class="x-page-btn">
            <el-button type="success" size="mini" round @click="handleAdd">添加赠品</el-button>
        </div>
        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border
                  @selection-change="handleSelectionChange">
            <el-table-column width="50" label="序号" type="index" fixed/>
            <el-table-column label="商品名称" prop="shangPinMC"/>
            <el-table-column label="商品规格" prop="ciJiGuiGeMC"/>
            <el-table-column label="赠品库存" prop="zengPinKuSL"/>
            <el-table-column label="剩余库存">
                <template slot-scope="scope">
                    <span v-if="!isDuoGuiGe(scope.row.shiFuDuoGuiGe)">{{scope.row.shangPinKuCun}}</span>
                    <span v-else>{{scope.row.guiGeShangPinKuCun}}</span>
                </template>
            </el-table-column>
            <el-table-column label="赠品状态">
                <template slot-scope="scope">
                        <el-switch @change="statusChange($event,scope.row)" v-model="scope.row.zengPinZT"
                                   active-value="true" inactive-value="false"></el-switch>
                </template>
            </el-table-column>
            <el-table-column width="250" align="center" label="操作" fixed="right">
                <template slot-scope="{ row, $index }">
                    <el-button type="primary" size="mini" round @click="inventorySet(row)">库存设置</el-button>
                    <el-popconfirm title="是否删除该商品并恢复库存?" @confirm="handleDelete(row.id)">
                        <el-button type="danger" size="mini" round slot="reference">删除</el-button>
                    </el-popconfirm>
                </template>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <!-- 编辑页区域 -->
        <zeng-pin-ku-set ref="refSet" @submitted="refresh"/>
        <zeng-pin-ku-add ref="refAdd" @submitted="refresh"/>
    </div>
</template>
<script>
    import * as service from "@/service/spgl/ShangPinXX";
    import ZengPinKuAdd from "@/view/spgl/zpk/ZengPinKuAdd";
    import ZengPinKuSet from "@/view/spgl/zpk/ZengPinKuSet";
    import XTableBase from "@/components/x/XTableBase";
    import {DICT_YES_OR_NO} from '@/util/constant'

    export default {
        name: "ZengPinKuList",
        mixins: [XTableBase],
        components: {ZengPinKuAdd, ZengPinKuSet},
        data() {
            this.refreshService = service.giveawayList;
            this.service = service;
            return {
                search: {
                    shangPinMC: '',
                    zengPinZT: '',
                }
            }
        },
        computed:{
           isDuoGuiGe(){
               return function (val) {
                   return val===DICT_YES_OR_NO.YES.key
               }
           }
        },
        methods: {
            //赠品状态调整
            statusChange(val, row) {
                let updateData = {
                    id: row.id,
                    zengPinZT: val,
                }
                service.giveawayStatusUpdate(updateData).then((response) => {
                    this.refresh()
                    this.$message.success(response.msg);
                })
            },
            inventorySet(row){
                this.$refs.refSet.showVisible = true
                this.$refs.refSet.rowData = row
            },
            handleAdd(){
                this.$refs.refAdd.showVisible = true;
            },
            handleDelete(id){
                this.service.giveawayDelete(id).then((response)=>{
                    this.$message.success(response.message)
                    this.refresh()
                })
            },
        }
    }
</script>

<style scoped>
</style>
