<template>
    <el-dialog title="库存设置" :visible.sync="showVisible" @open="open" @close="cancel"
               destroy-on-close center append-to-body width="800px" :close-on-click-modal="false">
        <el-form ref="form" :model="form" :rules="rules" class="x-edit">
            <el-form-item label="商品库存" class="line">
                <span>{{this.rowData.shiFuDuoGuiGe=="true"?this.rowData.guiGeShangPinKuCun:this.rowData.shangPinKuCun}}</span>
                <span style="color: rgb(153, 153, 153);"> 该库存不包含赠品库存</span>
            </el-form-item>
            <el-form-item label="赠品库存" class="line">
                <span>{{this.rowData.zengPinKuSL}}</span>
            </el-form-item>
            <el-form-item label="操作" class="line">
                <el-radio v-model="form.radio" label="true">增加库存</el-radio>
                <el-radio v-model="form.radio" label="false">减少库存</el-radio>
            </el-form-item>
            <el-form-item label="数量" prop="updateNum" class="line">
                <el-input-number :min="0" v-model="form.updateNum" @change="updateNumChange"
                                 label="请输入操作数量"></el-input-number>
            </el-form-item>
        </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="primary" @click="commit" icon="el-icon-document-add">确定</el-button>
        </span>
    </el-dialog>
</template>

<script>
    import * as service from "@/service/spgl/ShangPinXX";
    import {deepCopy} from "@/util/objects"
    import {ruleBuilder} from '@/util/validate';
    import {DICT_AUDIT_STATUS} from "@/util/constant"

    export default {
        name: "ZengPinKuSet",
        mixins: [],
        beforeCreate() {
            this.defaultForm = {
                shangPinKuCun: 0,
                zengPinKuSL: 0,
                guiGeShangPinKuCun: 0,
                radio: "true",
                updateNum: 0
            }
        },
        data() {
            let {required, buildMax, buildMin} = ruleBuilder;
            this.service = service;
            return {

                rules: {
                    updateNum: [
                        required('number', ['blur', 'change']),
                        {
                            validator: this.validatorNumber, trigger: "blur"
                        }
                    ]
                },
                rowData: {},
                showVisible: false,
                loading: false,
                form: deepCopy(this.defaultForm)
            }
        },
        methods: {
            validatorNumber(rule, value, callback) {
                if (this.form.radio == "true") {
                    if (value > this.form.shangPinKuCun) {
                        callback(new Error("增加的赠品数量不能超过剩余库存"))
                    } else {
                        callback()
                    }
                } else {
                    if (value > this.form.zengPinKuSL) {
                        callback(new Error("减少的赠品数量不能超过赠品库存"))
                    } else {
                        callback()
                    }
                }
            },
            open() {
                if (this.rowData.shiFuDuoGuiGe == "true") {
                    this.form.guiGeShangPinKuCun = this.rowData.guiGeShangPinKuCun
                } else {
                    this.form.shangPinKuCun = this.rowData.shangPinKuCun
                }
                this.form.zengPinKuSL = this.rowData.zengPinKuSL
                this.form.id = this.rowData.id
            },
            cancel() {
                //关闭弹出框
                this.rowData = {}
                this.showVisible = false;
            },
            commit() {
                this.form.auditStatus = DICT_AUDIT_STATUS.WAIT.key;
                this.save()

            },
            async save(callback) { // 通用save方法 增加回调
                this.loading = true;
                let isvalidate = false;
                try {
                    await this.$refs.form.validate((valid, message) => {
                        if (valid) { // 如果校验通过则提交
                            isvalidate = true;
                        } else { // 校验不通过
                            this.$message.error("有必填项没有填写请检查！");
                            this.loading = false;
                        }
                    });
                } catch (e) {
                    this.loading = false;
                    return;
                }
                if (!isvalidate) return;
                if (this.rowData.shiFuDuoGuiGe == "true") {
                    if (this.form.radio == "true") {
                        this.form.guiGeShangPinKuCun = this.form.guiGeShangPinKuCun - this.form.updateNum
                        this.form.shangPinKuCun = this.form.shangPinKuCun - this.form.updateNum
                        this.form.zengPinKuSL = this.form.zengPinKuSL + this.form.updateNum
                    } else {
                        this.form.guiGeShangPinKuCun = this.form.guiGeShangPinKuCun + this.form.updateNum
                        this.form.shangPinKuCun = this.form.shangPinKuCun + this.form.updateNum
                        this.form.zengPinKuSL = this.form.zengPinKuSL - this.form.updateNum
                    }
                } else {
                    if(this.form.radio == "true"){
                        this.form.shangPinKuCun = this.form.shangPinKuCun - this.form.updateNum
                        this.form.zengPinKuSL = this.form.zengPinKuSL + this.form.updateNum
                    }else{
                        this.form.shangPinKuCun = this.form.shangPinKuCun + this.form.updateNum
                        this.form.zengPinKuSL = this.form.zengPinKuSL - this.form.updateNum
                    }
                }
                const response = await this.service.giveawayUpdate(this.form).finally(() => {
                    this.loading = false; //TODO 更好的处理保存按钮加载问题
                });//执行保存方法
                if (callback && typeof callback === 'function') {
                    callback(response);
                }
                //打印提示信息
                this.$message.success(response.msg);
                this.$emit("submitted", response);
                this.cancel();
            },
        },
    }
</script>

<style scoped>

</style>
