<template>
    <el-dialog title="选择商品" :visible.sync="showVisible" @close="cancel" @open="open"
               destroy-on-close center append-to-body width="1900px">

        <div style="display: flex;justify-content:space-between;">
            <div class="x-page-container" ref="resize" style="width: 45%;height: 700px">
                <!-- 查询区 -->
                <div class="x-page-search">
                    <x-search-item label="商品名称">
                        <el-input v-model="searchOne.shangPinMC" size="small">
                        </el-input>
                    </x-search-item>
                    <div class="x-search-item" style="margin-left: 5px">
                        <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                    </div>
                </div>
                <!-- 列表区域 -->
                <el-table ref="table" v-loading="table.loading" :data="table.data" height="500px" border>
                    <el-table-column width="50" label="序号" type="index" fixed/>
                    <el-table-column width="180" prop="shangPinMC" label="商品名称"/>
                    <el-table-column  prop="ciJiGuiGeMC" label="商品规格"/>
                    <el-table-column  label="库存">
                        <template slot-scope="scope">
                            <span v-if="scope.row.shiFuDuoGuiGe=='true'">
                                {{scope.row.guiGeShangPinKuCun}}
                            </span>
                            <span v-else>
                                {{scope.row.shangPinKuCun}}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column  label="零售价">
                        <template slot-scope="scope">
                            <span v-if="scope.row.shiFuDuoGuiGe=='true'">
                                {{scope.row.guiGeShangPinLSJ}}
                            </span>
                            <span v-else>
                                {{scope.row.dianNeiLSJ}}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column width="180" align="center" label="操作" fixed="right">
                        <template slot-scope="{ row, $index }">
                            <el-button v-if="row.check=='false'" @click="selectOne(row)" type="text">选择</el-button>
                            <el-button v-else="row.check=='false'" style="color: rgb(102, 102, 102);" type="text">已选</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <!-- 分页区域 -->
                <el-pagination background
                               :total="table.total"
                               @size-change="handleSizeChange"
                               @current-change="handleCurrentChange"
                               layout="total, sizes, prev, pager, next, jumper"/>
            </div>
            <div class="x-page-container" ref="resize" style="width: 45%;height: 660px">
                <!-- 查询区 -->
                <div class="x-page-search">
                    <x-search-item label="商品名称">
                        <el-input v-model="searchTwo.shangPinMC" size="small">
                        </el-input>
                    </x-search-item>
                    <div class="x-search-item" style="margin-left: 5px">
                        <el-button type="primary" size="mini" @click="checkSearch">搜索</el-button>
                        <el-button size="mini" @click="clearSelectData" v-if="searchMidData.length>0">清空已选</el-button>
                    </div>
                </div>
                <!-- 列表区域 -->
                <el-table ref="table" v-loading="table.loading" :data="selectData" height="470px" border>
                    <el-table-column width="50" label="序号" type="index" fixed/>
                    <el-table-column width="180" prop="shangPinMC" label="商品名称"/>
                    <el-table-column  prop="ciJiGuiGeMC" label="商品规格">
                        <template slot-scope="scope">
                              <span v-if="scope.row.shiFuDuoGuiGe=='true'">
                                {{scope.row.ciJiGuiGeMC}}
                            </span>
                            <span v-else>
                                无规格
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column  label="库存">
                        <template slot-scope="scope">
                            <span v-if="scope.row.shiFuDuoGuiGe=='true'">
                                {{scope.row.guiGeShangPinKuCun}}
                            </span>
                            <span v-else>
                                {{scope.row.shangPinKuCun}}
                            </span>
                        </template>
                    </el-table-column>
                    <el-table-column  label="成本价" prop="zengPinChengBen"/>
                    <el-table-column width="180" label="赠品库存">
                        <template slot-scope="scope">
                                <el-input-number v-show="scope.row.shiFuDuoGuiGe=='true'" v-model="scope.row.zengPinKuSL" size="small" :min="0"></el-input-number>
                                <el-input-number v-show="scope.row.shiFuDuoGuiGe=='false'" v-model="scope.row.zengPinKuSL" size="small" :min="0"></el-input-number>
                        </template>
                    </el-table-column>
                    <el-table-column width="180" align="center" label="操作" fixed="right">
                        <template slot-scope="{ row, $index }">
                            <el-button @click="deleteOne(row.id)" type="text">删除</el-button>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
        </div>
        <span slot="footer" class="dialog-footer">
                    <el-button :disabled="this.searchMidData.length==0" type="primary" @click="commit" icon="el-icon-document-add">确定选择({{this.searchMidData.length}}/100)</el-button>
                </span>
    </el-dialog>
</template>

<script>
    import * as service from "@/service/spgl/ShangPinXX";
    import {getFenLeiSelector} from "@/service/spgl/ShangPinFenLei"
    import {getCurrentMenDianXXFromRedis} from "@/service/mdgl/MenDianXX"
    import {LOCAL_STORAGE} from "@/util/storage";
    import {ruleBuilder} from '@/util/validate';

    export default {
        name: "ZengPinKuAdd",
        mixins: [],
        data() {
            this.searchNoClear = {current: 1, size: 10};
            this.sels = [];
            this.pageSizes = [10, 20, 30, 40, 50, 100, 500, 1000];
            // this.refreshService = service.giveawayAddList;
            this.service = service;
            let {buildMax} = ruleBuilder;
            return {
                showVisible:false,
                searchOne:{
                    shangPinMC:""
                },
                searchTwo:{
                    shangPinMC:""
                },
                table: {
                    loading: false,
                    data: [],
                    total: 0,
                    height: 1000
                },
                selectData:[],
                searchMidData:[]
            }
        },
        activated() {
            this.$route.params.refresh && this.refresh();
            //解决fixed导致表格错位 激活页面重新计算表格布局
            this.$nextTick(() => {
                this.$refs.table.doLayout()
            })
        },
        methods: {
            cancel(){
                this.selectData = []
                this.searchMidData = []
                this.$emit("submitted")
                this.syncVisible = false
            },
            open(){
                this.refresh()
            },
            refresh() { //默认刷新列表方法
                this.table.loading = true;
                return this.service.giveawayAddList(Object.assign(this.searchOne, this.searchNoClear)).then((response) => {
                    let ret = response.data;
                    let ids = []
                    this.searchMidData.forEach(item=>ids.push(item.id))
                    ret.records.forEach((item)=>{
                        if(ids.indexOf(item.id)==-1){
                            item.check = 'false'
                        }else{
                            item.check = 'true'
                        }
                    })
                    this.table.data = ret.records;
                    this.table.total = ret.total || 0;
                    //处理11条数据删除一条不显示问题
                    if (this.searchNoClear.current !== 1 && (!ret.records || ret.records === 0)) {
                        this.searchNoClear.current--
                        this.refresh()
                        return
                    }
                    return response;
                }).catch(error => {
                    throw error;
                }).finally(() => this.table.loading = false)
            },
            handleSizeChange(val) { //默认当前页大小变化
                this.searchNoClear.size = val;
                return this.refresh();
            },
            handleCurrentChange(val) { //默认当前页变化
                this.searchNoClear.current = val;
                return this.refresh();
            },
            handleSearch() { //默认查询
                this.searchNoClear.current = 1;
                return this.refresh();
            },
            //选择商品为赠品
            selectOne(row){
                if(this.searchMidData.length==100){
                    this.$message.error("一次性最多添加100个赠品！")
                    return;
                }
                if(row.shiFuDuoGuiGe=='true'){
                    row.zengPinChengBen =row.guiGeShangPinLSJ
                }else{
                    row.zengPinChengBen = row.dianNeiLSJ
                }
                row.shiFuZengPin = 'true'
                row.zengPinZT = 'true'
                row.zengPinKuSL = 0
                this.table.data.forEach((item)=>{
                    if(row.id == item.id){
                        item.check = "true"
                    }
                })
                this.searchMidData.push(row)
                this.selectData.push(row)
            },
            //从已选中删除
            deleteOne(val){
                this.selectData = this.selectData.filter(item=>item.id!==val)
                this.searchMidData = this.searchMidData.filter(item=>item.id!==val)
                this.table.data.forEach((item)=>{
                    if(val == item.id){
                        item.check = "false"
                    }
                })
            },
            //已选列表数据筛选
            checkSearch(){
                this.selectData = this.searchMidData.filter(item=>item.shangPinMC.match(this.searchTwo.shangPinMC))
            },
            //清空已选
            clearSelectData(){
                this.table.data.forEach((item)=>{item.check = "false"})
                this.selectData = []
                this.searchMidData = []
            },
            commit(){
                let validator = true
                for(let i =0;i<this.searchMidData.length;i++){
                    if(this.searchMidData[i].shiFuDuoGuiGe=='true'){
                        if(this.searchMidData[i].zengPinKuSL>this.searchMidData[i].guiGeShangPinKuCun){
                            validator = false
                            break;
                        }
                        this.searchMidData[i].guiGeShangPinKuCun = this.searchMidData[i].guiGeShangPinKuCun - this.searchMidData[i].zengPinKuSL
                        this.searchMidData[i].shangPinKuCun = this.searchMidData[i].shangPinKuCun - this.searchMidData[i].zengPinKuSL
                    } else{
                        if(this.searchMidData[i].zengPinKuSL>this.searchMidData[i].shangPinKuCun){
                            validator = false
                            break;
                        }
                        this.searchMidData[i].shangPinKuCun = this.searchMidData[i].shangPinKuCun - this.searchMidData[i].zengPinKuSL
                    }
                }
                if(!validator){
                    this.$message.error("赠品中存在赠品数量大于仓库存量的情况，请检查！")
                    return;
                }
                this.table.loading = true;
                this.service.giveawayAdd(this.searchMidData).then((response)=>{
                    this.$message.success(response.msg);
                    this.selectData = []
                    this.searchMidData = []
                    this.refresh()
                })
            },
        }
    }
</script>

<style scoped>

</style>
